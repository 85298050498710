import axios from 'axios';
import {
  Dialog
} from 'vant';
import qs from 'qs';
import configUrl from './config';
// import router from '../router';
import { router } from '../router';
// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let requests = []
// 创建axios实例
const http = axios.create({
  baseURL: configUrl.api_base,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json', //axios默认的请求格式(json)
    'Authorization': 'Basic d3FsbDoxMjM0NTY=',
  },
})

function refreshToken() {
  // instance是当前request.js中已创建的axios实例
  const refresh_token = localStorage.getItem('wql_refresh_token')
  return http.post('oauth/token', {
    grant_type: "refresh_token",
    refresh_token: refresh_token
  }).then(res => res.data)
}
// request拦截器
http.interceptors.request.use(config => {
  const expires_in = localStorage.getItem('wql_expires_in')
  const newExp = parseInt(new Date().getTime() / 1000)
  const token = localStorage.getItem('wql_token') || localStorage.getItem('wql_real_token')||''
  config.headers['Authorization'] = 'Bearer ' + token
  config.headers['ContentType'] = "application/json"
  if (config.url.indexOf("matcherapi/v1/prediction/getsimpleMatch") != -1) {
    config.responseType = 'blob'
    return config
  }

  if (config.url == 'oauth/token') {
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      'Authorization': 'Basic d3FsbDoxMjM0NTY=',
      'tenant_id': 'eXFoZA==',
    }
    config.transformRequest = [function (data) {
      // return JSON.stringify(data)
      return qs.stringify(data)
    }]
    return config

  } else if (config.url === 'api/v1/login/sendSMS'  || config.url =='api/v1/login/mp/loginOpenid') {
    config.headers = {
      'Content-Type': "application/json",
      'Authorization': 'Basic d3FsbDoxMjM0NTY='
    }
    config.transformRequest = [function (data) {
      return JSON.stringify(data)
    }]
    return config

  } else if (config.url === 'api/v1/login/loginPhone' || config.url === 'userapi/v1/user/phoneResetPwd' || config.url==='api/v1/login/login0') {
    config.headers = {
      'Content-Type': "application/json",
      'tenant_id': 'eXFoZA==',
      'Authorization': 'Basic d3FsbDoxMjM0NTY='
    }
    config.transformRequest = [function (data) {
      return JSON.stringify(data)
    }]
    return config
  } else if (expires_in && localStorage.getItem('wql_refresh_token') && config.url !='api/v1/login/mp/loginOpenid') {
    if (expires_in - newExp < 60) { //shuax
      if (!isRefreshing) {
        console.log('刷新token ing')
        isRefreshing = true
        refreshToken().then(res => {
          console.log(res, '----');
          isRefreshing = false
          return token
        }).then(res => {
          if (res.error) {
            localStorage.clear()
            return
          }
          console.log('刷新token成功，执行队列')
          localStorage.setItem("wql_refresh_token", res.refresh_token);
          localStorage.setItem("wql_real_token", res.access_token);
          localStorage.setItem("wql_user_id", res.user_id);
          localStorage.setItem("wql_token", res.access_token);
          localStorage.setItem(
            "wql_expires_in",
            res.expires_in + parseInt(new Date().getTime() / 1000)
          );
          requests.forEach(cb => cb(res.access_token))
          // 执行完成后，清空队列
          requests = []
        }).catch(res => {
          localStorage.clear()
          console.error('refresh token error: ', res)
        })
      }
      const retryOriginalRequest = new Promise((resolve) => {
        requests.push((token) => {
          // 因为config中的token是旧的，所以刷新token后要将新token传进来
          config.headers['Authorization'] = 'Bearer ' + token
          resolve(config)
        })
      })
      return retryOriginalRequest
    }
    return config
  }else if (config.url === 'mediaapi/v1/media/file/upload/photo/1') {
    config.headers = {
      "Content-Type": "multipart/form-data",
      'Authorization': 'Bearer '+ token
    }
    config.transformRequest = [function (data) {
      return JSON.stringify(data)
    }]
    return config

  }
  return config
})

// respone拦截器
http.interceptors.response.use(
  response => {
    let data = response.data
    // let msg = data.message || ''
    if (data.code === 401) {
      // 账号禁用
      Dialog.alert({
        message: data.msg,
      }).then(() => {
        router.replace({
          name: 'login'
        })
      });
      return data
      // return Promise.reject(new Error(msg))
    } else if (data.code == 302) {
      // 登录失效 需重新登录
      Dialog.alert({
        message: data.msg,
      })
      router.replace({
        name: 'login'
      })
      return data
      // return Promise.reject(new Error(msg))
    } else if (data.code == 400) {
      Dialog.alert({
        message: data.msg,
      })
      return data
      // return Promise.reject(new Error(msg))
    }else if(data.code == 100001){
      return data
    }else if(data.code!=200&&!data.access_token&&data.code!=202){
      Dialog.alert({
        message: data.msg,
      })
      return data
      // return Promise.reject(new Error(msg))
    }
    return data
  },
  error => {
    if (error) {
      if(error.response.status == 401){//登录过期
        localStorage.clear()
        router.replace({
          name: 'login'
        })
      }else{
      Dialog.alert({
        message: error.response.data.msg|| error.response.data.error||'服务器异常，请稍后重试',
      }) 
      }



      // return router.replace({
      //   name: 'home'
      // })

      
      // localStorage.clear()
      // location.reload();
    }
    return error.response
    // return Promise.reject(error.response)
  }
)

export default http