<template>
  <div class="tabbar">
    <van-tabbar route>
      <van-tabbar-item
        v-for="(item, index) in tabbars"
        :key="index"
        :to="item.to"
      >
        <span>{{ item.title }}</span>
        <img
          slot="icon"
          slot-scope="props"
          :src="props.active ? item.active : item.normal"
        />
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import Vue from "vue";
import { Tabbar, TabbarItem } from "vant";
Vue.use(Tabbar);
Vue.use(TabbarItem);
export default {
  data() {
    //这里存放数据
    return {
      active: 0,
      tabbars: [
        {
          title: "首页",
          to: "/home",
          normal: require("@/img/tabbar/tabbar.png"),
          active: require("@/img/tabbar/tabbar-active.png"),
        },
        {
          title: "产品列表",
          to: "/productList",
          normal: require("@/img/tabbar/tabbar2.png"),
          active: require("@/img/tabbar/tabbar2-active.png"),
        },
        {
          title: "企业测额",
          to: "/measurement",
          normal: require("@/img/tabbar/tabbar3.png"),
          active: require("@/img/tabbar/tabbar3-active.png"),
        },
        {
          title: "个人中心",
          to: "/my",
          normal: require("@/img/tabbar/tabbar4.png"),
          active: require("@/img/tabbar/tabbar4-active.png"),
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}
</style>
