// import { resolve } from 'core-js/fn/promise';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    name: 'home',
    // component: () => import('@/view/home'),
    component: resolve => require(['@/view/home'],resolve),
    meta: {
      title: '首页',
      isLogin:false
    }
  },
  {
    name: 'measurement',
    // component: () => import('@/view/ceE'),
    component: resolve => require(['@/view/ceE'],resolve),
    meta: {
      title: '智能匹配',
      isLogin:true
    }
  },
  {
    name: 'code',
    // component: () => import('@/view/code/code'),
    component: resolve => require(['@/view/code/code'],resolve),

    meta: {
      title: '验证码',
      isLogin:true
    }
  },
  {
    name: 'match',
    // component: () => import('@/view/match'),
    component: resolve => require(['@/view/match'],resolve),

    meta: {
      title: '匹配',
      isLogin:true
    }
  },
  {
    name: 'match2',
    // component: () => import('@/view/match2'),
    component: resolve => require(['@/view/match2'],resolve),

    meta: {
      title: '匹配查询',
      isLogin:true
    }
  },
  {
    name: 'result',
    // component: () => import('@/view/result'),
    component: resolve => require(['@/view/result'],resolve),

    meta: {
      title: '审核结果',
      isLogin:true
    }
  },
  {
    name: 'productList',
    // component: () => import('@/view/productList'),
    component: resolve => require(['@/view/productList'],resolve),

    meta: {
      title: '产品列表',
      isLogin:false
    }
  },
  {
    name: 'my',
    // component: () => import('@/view/my'),
    component: resolve => require(['@/view/my'],resolve),

    meta: {
      title: '个人中心',
      isLogin:false
    }
  },
  {
    name: 'productInfo',
    // component: () => import('@/view/productInfo'),
    component: resolve => require(['@/view/productInfo'],resolve),

    meta: {
      title: '产品详情',
      isLogin:false
    }
  },
  {
    name: 'platformInfo',
    // component: () => import('@/view/platformInfo'),
    component: resolve => require(['@/view/platformInfo'],resolve),

    meta: {
      title: '平台简介',
      isLogin:false
    }
  },
  {
    name: 'companyList',
    // component: () => import('@/view/company'),
    component: resolve => require(['@/view/company/list'],resolve),

    meta: {
      title: '企业列表',
      isLogin:true
    }
  },
  {
    name: 'company',
    // component: () => import('@/view/company'),
    component: resolve => require(['@/view/company'],resolve),

    meta: {
      title: '企业详情',
      isLogin:true
    }
  },
  {
    name: 'consultant',
    // component: () => import('@/view/consultant'),
    component: resolve => require(['@/view/consultant'],resolve),

    meta: {
      title: '专属顾问',
      isLogin:true
    }
  },
  {
    name: 'login',
    // component: () => import('@/view/login'),
    component: resolve => require(['@/view/login'],resolve),

    meta: {
      title: '登录',
      isLogin:false
    }
  },
  {
    name: 'loginReset',
    // component: () => import('@/view/loginReset'),
    component: resolve => require(['@/view/loginReset'],resolve),

    meta: {
      title: '重置密码',
      isLogin:false
    }
  },
  {
    name: 'matchRecord',
    // component: () => import('@/view/matchRecord'),
    component: resolve => require(['@/view/matchRecord'],resolve),

    meta: {
      title: '匹配记录',
      isLogin:true
    }
  },
  {
    name: 'pipeiChanPin',
    // component: () => import('@/view/matchedDetail'),
    component: resolve => require(['@/view/matchedDetail'],resolve),

    meta: {
      title: '贷款方案',
      isLogin:true
    }
  },

  {
    name: 'matchDetail',
    // component: () => import('@/view/matchDetail'),
    component: resolve => require(['@/view/matchDetail'],resolve),

    meta: {
      title: '贷款最终匹配方案',
      isLogin:true
    }
  },
  {
    name: 'agreement',
    // component: () => import('@/view/agreement'),
    component: resolve => require(['@/view/agreement'],resolve),

    meta: {
      title: '隐私协议',
      isLogin:false
    }
  },
  {
    name: 'about',
    // component: () => import('@/view/about'),
    component: resolve => require(['@/view/about'],resolve),

    meta: {
      title: '关于我们',
      isLogin:false
    }
  },
  {
    name: 'feedback',
    // component: () => import('@/view/feedback'),
    component: resolve => require(['@/view/feedback'],resolve),

    meta: {
      title: '用户反馈',
      isLogin:true
    }
  },
  {
    name: 'home2',
    // component: () => import('@/view/home2'),
    component: resolve => require(['@/view/home2'],resolve),
    
    meta: {
      title: '首页',
      isLogin:false
    }
  },
  {
    name: 'companyService',
    // component: () => import('@/view/companyService'),
    component: resolve => require(['@/view/companyService'],resolve),

    meta: {
      title: '企业服务',
      isLogin:false
    }
  },
  {
    name: 'companyReport',
    // component: () => import('@/view/companyReport'),
    component: resolve => require(['@/view/companyReport'],resolve),

    meta: {
      title: '企业报告',
      isLogin:false
    }
  },
  {
    name: 'wxLogin',
    // component: () => import('@/view/wxLogin'),
    component: resolve => require(['@/view/wxLogin'],resolve),

    meta: {
      title: '微信授权登录',
      isLogin:false
    }
  },
  {
    name: 'search',
    // component: () => import('@/view/search'),
    component: resolve => require(['@/view/search'],resolve),

    meta: {
      title: '搜索',
      isLogin:false
    }
  },
  {
    name: 'androidAgreement',
    // component: () => import('@/view/search'),
    component: resolve => require(['@/view/androidAgreement'],resolve),
    meta: {
      title: '安卓协议',
      isLogin:false
    }
  },
  {
    name: 'vipNo',
    component: resolve => require(['@/view/vipNo'],resolve),
    meta: {
      title: '暂未开通VIP',
      isLogin:false
    }
  },
  {
    name: 'vip',
    component: resolve => require(['@/view/vip'],resolve),
    meta: {
      title: 'VIP详情',
      isLogin:true
    }
  },
  {
    name: 'vipInterests',
    component: resolve => require(['@/view/vip/interests'],resolve),
    meta: {
      title: 'VIP权益',
      isLogin:true
    }
  },
  {
    name: 'course',
    component: resolve => require(['@/view/course'],resolve),
    meta: {
      title: '课程详情',
      isLogin:true
    }
  },
  {
    name: 'courseInfo',
    component: resolve => require(['@/view/course/info.vue'],resolve),
    meta: {
      title: '课程',
      isLogin:true
    }
  },
  {
    name: 'collection',
    // component: () => import('@/view/productList'),
    component: resolve => require(['@/view/productList/collection'],resolve),
    meta: {
      title: '我的收藏',
      isLogin:true
    }
  },
  {
    name: 'message',
    component: resolve => require(['@/view/message'],resolve),
    meta: {
      title: '消息',
      isLogin:true
    }
  },
  {
    name: 'messageSystem',
    component: resolve => require(['@/view/message/system'],resolve),
    meta: {
      title: '系统消息',
      isLogin:true
    }
  },
  {
    name: 'myCompany',
    component: resolve => require(['@/view/company/my'],resolve),
    meta: {
      title: '我的企业',
      isLogin:true
    }
  },
  {
    name: 'report',
    component: resolve => require(['@/view/report/index'],resolve),
    meta: {
      title: '融资报告',
      isLogin:true
    }
  },
  {
    name: 'yl',
    component: resolve => require(['@/view/vipNo/yl.vue'],resolve),
    meta: {
      title: '融资报告样例',
      isLogin:false
    }
  },
  {
    name: 'chat',
    component: resolve => require(['@/view/chat/index'],resolve),
    meta: {
      title: '专属顾问',
      isLogin:true
    }
  },
  {
    name: 'qa',
    component: resolve => require(['@/view/chat/qa'],resolve),
    meta: {
      title: '智能客服',
      isLogin:true
    }
  },
  {
    name: 'seting',
    component: resolve => require(['@/view/seting'],resolve),
    meta: {
      title: '设置',
      isLogin:true
    }
  },
  {
    name: 'shareApp',
    component: resolve => require(['@/view/shareApp'],resolve),
    meta: {
      title: 'App分享',
      isLogin:true
    }
  },
  {
    name: 'bank',
    component: resolve => require(['@/view/bank'],resolve),
    meta: {
      title: '银行列表',
      isLogin:false
    }
  },
  {
    name: 'download',
    component: resolve => require(['@/view/download'],resolve),
    meta: {
      title: '下载',
      isLogin:false
    }
  },
  {
    name: 'gzh',
    component: resolve => require(['@/view/gzh'],resolve),
    meta: {
      title: '关注公众号',
      isLogin:false
    }
  },
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  // const title = to.meta && to.meta.title;
  // if (title) {
  //   document.title = title;
  // }
  let isLogin = localStorage.getItem("wql_real_token")
  if(to.meta.isLogin && !isLogin &&isLogin!="undefined"){
    next("/login");
  }
  next();
});

export  {
  router
};
