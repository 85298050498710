const root = ''

const url = {
  // port 8023,Company API  
  
  dict: root + 'adminapi/v1/dict/all', //字典
  address: root + 'companyapi/v1/area/getProvinceCity', //城市列表
  sendSMS: root + 'api/v1/login/sendSMS', //获取授权验证码，返回法人姓名手机号
  login: root + 'oauth/token', //获取授权验证码，返回法人姓名手机号 //访客登录
  phoneResetPwd: root + 'userapi/v1/user/phoneResetPwd', //重置密码
  banner:root+'snsapi/v1/bannerImg/list?status=0',//首页banner
  homeRoll:root+'matcherapi/v2/prediction/getHomeList',//首页走马灯
  bankList:root+'productapi/v2/institution/list',//银行列表
  recommendedProduct:root+'productapi/v1/recommendProduct/list',//推荐产品列表
  getProductList:root+'productapi/v1/productOnLine/getProductList',//产品列表
  companyList:root+'companyapi/v2/company/getCompanyList',//企业列表
  matchingProduct:root+'matcherapi/v2/prediction/getProductListByCompanyId',//匹配产品
  failureCause:root+'matcherapi/v2/prediction/getProductFailReason',//匹配失败原因
  productInfo:root+'productapi/v1/productOnLine/getProductDetail/',//产品详情
  recommendProductInfo:root+'productapi/v1/recommendProduct/detail',//推荐产品详情
  handleLinks:root+'productapi/v1/productOnLine/handleLinks',//办理记录
  getCompanyDetail:root+'companyapi/v2/company/getCompanyDetail',//获取企业信息
  getMatchList:root+'matcherapi/v2/prediction/getMatchList',//查询匹配记录
  feedback:root+'snsapi/v1/feedBack/add',//用户反馈
  loginPhone:root+'api/v1/login/loginPhone',//登录
  wxLogin:root+'api/v1/login/mp/login',//公众号授权登录
  wxOpenid:root+'api/v1/login/mp/loginOpenid',//公众号Openid登录
  userInfo:root+'userapi/v1/user/getDetail',//用户信息
  consultant:root+'crmapi/v1/consultant',//专属顾问
  afterAuth:root+'matcherapi/v2/prediction/getProductListAfterAuth?loanPredictionId=',//轮询测额


  userNameLoginOrRefToken:root+'oauth/token',//刷新token或者正常用户名密码登录
  usenameLogin:root+'api/v1/login/login0',//刷新token或者正常用户名密码登录
  
  // 测额页面api
  matchCompany:root+'companyapi/v1/businessSearch/match/',//通过名称匹配公司信息
  companyBasicTypeInfo:root+'companyapi/v2/companyBasic/tyBaseInfo?name=',//通过企业全称检索企业地区
  getLoginCode2:root+"companyapi/v2/taxLogin/getLoginCode",//通过企业全称检索企业地区
  shuiwu:root+"companyapi/v2/taxLogin/login",//登录税务局
  getLoginCode: root + 'companyapi/v1/taxLogin/getLoginCode', //获取授权验证码，返回法人姓名手机号
  getLoanPredictionBeforeAuth: root + 'matcherapi/v2/prediction/getLoanPredictionBeforeAuth?loanPredictionId=', //通过关联Id查询产品
  loginCompensation: root + 'companyapi/v2/taxLogin/loginCompensation', //匹配补偿接口
  // auditResult: root + 'matcherapi/v1/prediction/auditResult?predictId=', //产看核心结果
  getLoginCode3: root + 'matcherapi/v1/bankTax/getLoginCode', //核心页面认证登录获取验证码
  getLogin4: root + 'matcherapi/v1/bankTax/getLogin', //核心页面认证登录提交验证码
  photo: root + 'mediaapi/v1/media/file/upload/photo/1', //上传头像
  upUser: root + 'userapi/v1/user', //更新用户信息
  // matcherapi/v2/prediction/getProductListAfterAuth?loanPredictionId

  hotProduct: root + 'productapi/v1/recommendProduct/hotProduct', //热门产品列表
  getCourseDetail: root + 'mobileapi/v1/course/getCourseDetail', //根据课程ID获取课程详情
  getM3u8: root + '/snsapi/v1/course/video/', //获取课程内容的M3U8文件
  getProductListAfterAuthV2: root + 'mobileapi/v2/prediction/getProductListAfterAuthV2', //获取贷款匹配方案
  getSuccessProductListByCompanyId: root + 'mobileapi/v2/prediction/getSuccessProductListByCompanyId', //获取贷款匹配方案（无需参数）
  companyList2:root+'companyapi/v1/companyUser/getCompanyUserList',//企业列表 新
  bindCompany:root+'mobileapi/v1/companyUser/addCompanyUser',//绑定企业
  wapPayAli:root+'paymentapi/v1/allPay/wapPayAli',//支付宝手机网站支付
  collect:root+'productapi/v1/product/collect',//收藏产品
  getCollectPage:root+'productapi/v1/product/getCollectPage',//收藏列表
  getStationMsg:root+'messageapi/v1/stationMsg/getStationMsg',//同步app栈内消息
  listAndStation:root+'messageapi/v1/msgRecord/listAndStation',//获取系统消息
  report:root+'companyapi/v1/creditReport/vip/report',//融资报告
  chatList:root+'imapi/v1/chat/conversation/list',//会话列表
  unMessageAll:root+'imapi/v1/chat/unMessageAll',//会话未读消息
  chatHistory:root+'imapi/v1/chat/message/pull/',//会话历史消息
  userSend:root+'imapi/v1/chat/userSend',//发送消息
  sequence:root+'imapi/v1/chat/sequence',//消息已读
  question:root+'imapi/v1/chat/question',//智能客服提问
  getAnswer:root+'imapi/v1/chat/getAnswer',//获取答案
  autoansid:root+'imapi/v1/chat/autoansid',//点击问答
  getApp:root+'snsapi/v1/upgrade/listPage?size=1',//获取APP下载地址
  getVipList:root+'userapi/v1/vip/getVipList',//获取vip列表
  saveUserOrder:root+'userapi/v1/userOrder/saveUserOrder',//获取vip支付信息


  aaaa:root + 'paymentapi/v1/allPay/jaspiPayWx'//微信支付测试
  
}

export default url;