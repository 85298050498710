export default {
  //接口基础地址
  // api_base: process.env.NODE_ENV === 'development' ? 'http://192.168.0.182:8010/' : '',//小伟
  // api_base: process.env.NODE_ENV === 'development' ? 'http://192.168.0.174:8010/' : '',
  // api_base: process.env.NODE_ENV === 'development' ? 'http://192.168.0.168:8010/' : '',
  // api_base: process.env.NODE_ENV === 'development' ? 'http://api.weiqilile.com/' : '',
  
  
  // api_base: process.env.NODE_ENV === 'development' ? 'http://192.168.0.2:8010/' : 'http://192.168.0.2:8010/',


  api_base: process.env.NODE_ENV === 'development' ? 'https://api.weiqilile.com' : 'https://api.weiqilile.com/', //线上地址
  corpId: 'dingb97de3d853226a7235c2f4657eb6378f', // 正式
  appId: '1040830345' // 正式

}
// /deleteFile