import Vue from 'vue';
import App from './App';
import { router } from './router';
import 'amfe-flexible';
import { NavBar, Form,Sticky ,Search ,Cascader  , Field,Popup,Dialog,Loading, Toast,Overlay  ,Cell,CellGroup,Rate, PasswordInput, NumberKeyboard , Button,Picker ,Grid,RadioGroup,Radio,GridItem,ActionSheet ,Swipe,SwipeItem,Icon,Col, Row ,Tab, Tabs,List ,Empty,DropdownMenu, DropdownItem,Uploader,Collapse, CollapseItem,Image as VanImage  } from 'vant';
import http from './service/http'; 
import hls from 'videojs-contrib-hls'
import VideoPlayer from 'vue-video-player';
import "video.js/dist/video-js.css"
import "vue-video-player/src/custom-theme.css"
import ECharts from "vue-echarts";
Vue.component('v-chart', ECharts)//全局注册
Vue.use(hls)
Vue.use(VideoPlayer)
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Rate)
Vue.use(Loading)
Vue.use(Toast)
Vue.use(PasswordInput)
Vue.use(NumberKeyboard)
Vue.use(Dialog)
Vue.use(Picker)
Vue.use(Field)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Button) 
Vue.use(ActionSheet)
Vue.use(Cell);
Vue.use(CellGroup)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Icon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Empty);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Uploader);
Vue.use(VanImage);
Vue.use(Overlay);
Vue.use(Sticky);
Vue.use(Search);
Vue.use(Cascader);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.prototype.$http = http;
new Vue({
  mode: 'history',
  router,
  el: '#app',
  render: h => h(App)
});

