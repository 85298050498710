<template>
  <div id="app">
    <navBar v-if="navBar" :title="title" />
    <router-view :chatVal="chatVal" v-if="token" />
    <tabbar v-if="show && isOriginHeight" />
    <!-- <div class="kefu-app" @click="toKefu" v-if="kefu">
      <img src="@/img/consultant.png" alt="" class="kefu-app-img" />
      <div class="kefu-app-text">专属顾问</div>
    </div> -->
  </div>
</template>
<script>
import tabbar from "@/components/tabbar.vue";
import navBar from "@/components/navBar.vue";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import url from "@/service/url-config";
export default {
  components: {
    tabbar,
    navBar,
  },
  data() {
    return {
      chatVal: "",
      show: false,
      navBar: false,
      kefu: false,
      title: "",
      screenHeight: document.documentElement.clientHeight, // 当前高度
      originHeight: document.documentElement.clientHeight, //默认高度，用作比较
      isOriginHeight: true, //当前高度是否仍等于默认高度
      token: null, //虚拟token
    };
  },
  created() {
    if (
      localStorage.getItem("wql_real_token") &&
      localStorage.getItem("wql_token") &&
      localStorage.getItem("wql_real_token") != "undefined"
    ) {
      this.token = localStorage.getItem("wql_token");
      this.$http.get(url.chatList).then((res) => {
        let msg = JSON.stringify({
          num: res.data[0].unread,
          time: res.data[0].gmtCreate,
          sequenceId: res.data[0].sequenceId,
          receiver: res.data[0].receiver,
          conversationId: res.data[0].conversationId,
        });
        localStorage.setItem("msg", msg);
      });
    } else {
      localStorage.clear();
      this.$http
        .post(url.login, {
          grant_type: "client_credentials",
          appVersion: "1.0.0",
          appId: "web",
        })
        .then((res) => {
          this.token = res.access_token;
          localStorage.setItem("wql_token", res.access_token);
        });
    }
    //建立socket 连接
    let this_ = this;
    let socket = new SockJS(`https://api.weiqilile.com/imapi/wss`);
    // let socket = new SockJS(`http://192.168.0.174:8010/imapi/wss`);
    let stompClient = Stomp.over(socket);
    let headers = {
      Authorization: localStorage.getItem("wql_token"),
    };
    stompClient.connect(headers, function (frame) {
      console.log(frame);
      stompClient.subscribe("/user/topic/reply", function (message) {
        let data = JSON.parse(message.body);
        if (this_.$router.history.current.name == "chat") {
          if (data.command) {
            this_.$http
              .get(
                url.unMessageAll +
                  `?sequenceId=${data.sequenceId ? data.sequenceId : ""}`
              )
              .then((res) => {
                if (res.code == 200) {
                  this_.chatVal = res.data.message;
                }
              });
          }
        } else {
          this_.$http.get(url.chatList).then((res) => {
            let msg = JSON.stringify({
              num: res.data[0].unread,
              time: res.data[0].gmtCreate,
              sequenceId: res.data[0].sequenceId,
              receiver: res.data[0].receiver,
              conversationId: res.data[0].conversationId,
            });
            localStorage.setItem("msg", msg);
          });
        }
      });
      stompClient.subscribe("/user/queue/qa", function (message) {
        let data = JSON.parse(message.body);
        if (data.type == 2) {
          this_.chatVal = data;
        }
      });
    });
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.screenHeight = document.documentElement.clientHeight;
      })();
    };
  },
  methods: {
    toKefu() {
      this.$router.push("consultant");
    },
  },
  watch: {
    $route(e) {
      if (
        e.name == "home" ||
        e.name == "measurement" ||
        e.name == "productList" ||
        e.name == "my" ||
        e.name == "home2" ||
        e.name == "companyService"
      ) {
        this.show = true;
        this.navBar = false;
      } else if (
        e.name == "code" ||
        e.name == "match" ||
        e.name == "match2" ||
        e.name == "wxLogin" ||
        e.name == "search" ||
        e.name == "androidAgreement" ||
        e.name == "vip" ||
        e.name == "download"
      ) {
        this.show = false;
        this.navBar = false;
      } else {
        this.show = false;
        this.navBar = true;
      }
      this.title = e.meta.title;
      //控制客服图标展示隐藏
      if (
        e.name == "home" ||
        e.name == "productList" ||
        e.name == "productInfo"
      ) {
        this.kefu = true;
      } else {
        this.kefu = false;
      }
      let userAgent = navigator.userAgent;
      if (
        userAgent.indexOf("wqllapp") != -1 &&
        e.name != "caseInfo" &&
        e.name != "case"
      ) {
        this.navBar = false;
      }
    },
    screenHeight(val) {
      if (this.originHeight - val > 60) {
        //这里以防万一改了下判断形式，当屏幕高度减少了60px以上时才隐藏footer
        this.isOriginHeight = false;
      } else {
        this.isOriginHeight = true;
      }
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
.kefu-app {
  position: fixed;
  right: 16px;
  top: 300px;
}
.kefu-app-img {
  width: 52px;
  height: 52px;
}
.kefu-app-text {
  color: #1377ff;
  font-size: 10px;
}
</style>
