<template>
  <div class="nav-bar">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
  </div>
</template>
<script>
export default {
  props: ["title"],
  data() {
    //这里存放数据
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    onClickLeft() {
      let userAgent = navigator.userAgent;
      if (this.title == "成功案例" && userAgent.indexOf("wqllapp") != -1) {
        /* eslint-disable */
        android.backAndroid();
      } else {
        this.$router.go(-1);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.nav-bar {
  width: 100%;
  height: 46px;
  box-shadow: 0px 2px 4px 0px rgba(204, 204, 204, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 99;
}
</style>